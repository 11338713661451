import React, { useContext, useState } from "react";
import Confetti from 'react-confetti';
import ConfettiExplosion from 'react-confetti-explosion';

import { PiTimerBold, PiConfettiDuotone } from "react-icons/pi";
import { PuzzleContext } from "../context/PuzzleContext";
import { Social } from "./Social";
import { ImTerminal } from "react-icons/im";
import { RxLinkedinLogo } from "react-icons/rx";
import { LOCAL_STORAGE_KEY, useMountEffect } from "../helpers/utils";
import { MiniGameGrid } from "./MiniGameGrid";

function WinModal(props) {
    const ctx = useContext(PuzzleContext).puzzleInfo;
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [showConfetti, setShowConfetti] = useState(true);

    const detectSize = () => {
        setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    useMountEffect(() => {
        window.addEventListener('resize', detectSize)
        const timer = setTimeout(() => setShowConfetti(false), 7000);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(ctx.userData));

        return () => {
            window.removeEventListener('resize', detectSize)
            clearTimeout(timer);
        }
    });

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const url = 'http://climbcrossunlimited.com';
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;

    return (
        <div className="win modal">
            {showConfetti && (
                <>
                    <Confetti
                        style={{ zIndex: -1 }}
                        width={windowDimensions.width}
                        height={windowDimensions.height}
                        colors={['lightsteelblue', 'steelblue', 'darkorange', 'salmon', 'white']}
                        numberOfPieces={200}
                        recycle={false}
                    />
                    <div className="confetti-container">
                        <ConfettiExplosion
                            zIndex={-1}
                            force={0.8}
                            duration={5000}
                            particleCount={300}
                            width={windowDimensions.width}
                            colors={['lightsteelblue', 'steelblue', 'darkorange', 'salmon', 'white']}
                        />
                    </div>
                </>
            )}
            <span>Climbcross #{ctx.puzzleIndex + 1}</span>
            <span>
                <PiConfettiDuotone />
                <h3>You Win</h3>
                <PiConfettiDuotone />
            </span>
            <div className="timer">
                <PiTimerBold />
                {formatTime(ctx.time)}
            </div>
            <MiniGameGrid puzzle={ctx.puzzle} swapped={ctx.swapped}/>
            <div className="share-options">
                <span>Had fun? Explore or Share below!</span>
                <div>
                    <div className="share-option">
                        <Social label="portfolio" href="https://tdepodesta.github.io">
                            <ImTerminal />
                        </Social>
                        <span>Portfolio</span>
                    </div>
                    <div className="share-option">
                        <Social label="linkedin" href={linkedInShareUrl}>
                            <RxLinkedinLogo />
                        </Social>
                        <span>Share Site</span>
                    </div>
                </div>
            </div>
            <button onClick={() => props.onNext(null)}>Play Next Puzzle</button>
        </div>
    )
}

export { WinModal }