import React, { useContext, useEffect, useState } from "react";

import { Crossclimb } from "./Crossclimb";
import { Social } from "./Social";
import { ModalContext } from "../context/ModalContext";
import { PuzzleContext } from '../context/PuzzleContext.js';
import { InstructionsModal } from "./InstructionsModal";
import { useMountEffect } from "../helpers/utils";

import { SiGmail } from "react-icons/si";
import { RxLinkedinLogo } from "react-icons/rx";
import { ImTerminal } from "react-icons/im";
import { PiQuestionMarkBold } from "react-icons/pi";

function Site() {
    const numPuzzles = useContext(PuzzleContext).puzzleInfo.numPuzzles;
    const { Modal, setModal } = useContext(ModalContext);
    const [isMobile, setIsMobile] = useState(false);

    useMountEffect(() => {
        if (window.innerWidth > 450) {
            setModal(<InstructionsModal/>);
        }
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 450);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <header>
                <h1>Climbcross*</h1>
                <h3>Unlimited<span>**</span></h3>
                <div>
                    <button onClick={() => setModal(<InstructionsModal/>)}><PiQuestionMarkBold/></button>
                    <div id="socials">
                        <Social label="portfolio" href="https://tdepodesta.github.io">
                            <ImTerminal />
                        </Social>
                        <Social label="gmail" href="mailto:tdepodesta@college.harvard.edu?subject=Climbcross Rocks!">
                            <SiGmail />
                        </Social>
                        <Social label="linkedin" href="https://www.linkedin.com/in/trevor-depodesta-4225b1290/">
                            <RxLinkedinLogo />
                        </Social>
                    </div>
                </div>
            </header>
            <main>
                {!isMobile ? (
                    <Crossclimb/>
                ) : (
                    <div>
                        <p id="mobile-message">This game is designed to be played on a larger computer screen. Until then, feel free to explore the mobile-friendly links through the icons above, or familiarize yourself with the game rules below!</p>
                        <span/>
                        <InstructionsModal/>
                    </div>
                )}
            </main>
            <footer>
                <p><b>*</b> totally legally distinct from whatever game you're thinking of<b>**</b> {numPuzzles} ~<i>ish</i>~</p>
                <p>© Trevor DePodesta 2024</p>
            </footer>
            <Modal/>
        </>
    )
}

export { Site }