import React, { useState, useEffect, useContext } from 'react';

import { PiEqualsBold, PiLockKeyOpen, PiLockKeyFill } from "react-icons/pi";
import { PuzzleContext } from '../context/PuzzleContext';

function Row(props) {
    const ctx = useContext(PuzzleContext).puzzleInfo;
    const [isDragging, setIsDragging] = useState(false);
    const inputRefs = props.inputRefs;

    const handleChange = (i, value) => {
        props.handleInputChange(props.id, i, value);
        if (value && i < 3) {
            inputRefs[i + 1].current.focus();
        }
    };

    const handleKeyDown = (e, i) => {
        switch (e.key) {
            case 'ArrowLeft':
                if (i > 0) {
                    e.preventDefault();
                    inputRefs[i - 1].current.focus();
                }
                break;
            case 'ArrowRight':
                if (i < 3) {
                    e.preventDefault();
                    inputRefs[i + 1].current.focus();
                }
                break;
            case 'ArrowUp':
                e.preventDefault();
                props.onFocusChange('up', props.id);
                break;
            case 'ArrowDown':
                e.preventDefault();
                props.onFocusChange('down', props.id);
                break;
            case 'Backspace':
                if (!ctx.userInput[props.id][i] && i > 0) {
                    e.preventDefault();
                    inputRefs[i - 1].current.focus();
                }
                break;
            default:
                break;
        }
    };

    const handleFocus = (e) => {
        const length = e.target.value.length;
        e.target.setSelectionRange(length, length);
    };

    useEffect(() => {
        inputRefs.forEach(ref => {
            if (ref.current) {
                ref.current.addEventListener('focus', handleFocus);
            }
        });
        return () => {
            inputRefs.forEach(ref => {
                if (ref.current) {
                    ref.current.removeEventListener('focus', handleFocus);
                }
            });
        };
    }, [inputRefs]);

    useEffect(() => {
        if (props.snapshot?.isDragging) {
            // Small delay to ensure the initial state is rendered
            const timer = setTimeout(() => {
                setIsDragging(true);
            }, 50);
            return () => clearTimeout(timer);
        } else {
            setIsDragging(false);
        }
    }, [props.snapshot?.isDragging]);

    return (
        <div className={`row-container ${props.end ? 'immutable' : ''}`}>
            <span className='handle' {...(!props.middleSolved && props.provided?.dragHandleProps)} tabIndex="-1">
                <span className={`handle-icon ${props.middleSolved ? '' : 'active'}`}><PiEqualsBold/></span>
            </span>
            <div className={`word ${isDragging ? 'dragging' : ''} ${props.isIncorrect ? 'incorrect' : ''}`}>
                {props.immutable ? (props.middleSolved ? <PiLockKeyOpen/> : <PiLockKeyFill/>) : (
                    ctx.userInput[props.id].map((letter, i) => (
                        <input
                            key={i}
                            ref={inputRefs[i]}
                            type="text"
                            maxLength={1}
                            value={letter}
                            onChange={(e) => handleChange(i, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, i)}
                            onFocus={() => props.setCurrentRow(props.id)}
                            disabled={(props.middleSolved && !props.end) || props.solved}
                        />
                    ))
                )}
            </div>
            <span className='handle' {...(!props.middleSolved && props.provided?.dragHandleProps)} tabIndex="-1">
                <span className={`handle-icon ${props.middleSolved ? '' : 'active'}`}><PiEqualsBold/></span>
            </span>
        </div>
    )
}

export { Row }