import React from 'react';

function MiniGameGrid(props) {
    const grid = Array(4).fill().map(() => Array(7).fill(''));
    let puzzle = [...props.puzzle]; 
    
    if (props.swapped) {
        const temp = puzzle[0];
        puzzle[0] = puzzle[puzzle.length - 1];
        puzzle[puzzle.length - 1] = temp;
    }

    puzzle.forEach((wordObj, colIndex) => {
        const letters = wordObj.word.split('');
        letters.forEach((letter, rowIndex) => {
            grid[rowIndex][colIndex] = letter;
        });
    });

    const getCellClass = (rowIndex, colIndex) => {
        let classes = [];

        if (colIndex === 0 || colIndex === 6) {
            classes.push('end-cell');
        }

        if (colIndex > 0) {
            if (grid[rowIndex][colIndex - 1] !== grid[rowIndex][colIndex]) {
                classes.push('post');
            }
        }

        if (colIndex < 6) {
            if (grid[rowIndex][colIndex + 1] !== grid[rowIndex][colIndex]) {
                classes.push('pre');
            }
        }

        return classes.join(' ');
    };

    return (
        <div className="mini-game-grid">
            {grid.map((row, rowIndex) =>
                row.map((letter, colIndex) => (
                    <span
                        key={`${rowIndex}-${colIndex}`}
                        className={getCellClass(rowIndex, colIndex)}
                    >
                        {letter}
                    </span>
                ))
            )}
        </div>
    );
};

export { MiniGameGrid }