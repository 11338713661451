import React, { useContext, useEffect, useRef, useState } from 'react';
import { PuzzleContext } from '../context/PuzzleContext';

function HintButton(props) {
    const ctx = useContext(PuzzleContext).puzzleInfo;
    const [hintCooldown, setHintCooldown] = useState(0);
    const hintButtonRef = useRef(null);

    useEffect(() => {
        let interval;
        if (hintCooldown > 0) {
            interval = setInterval(() => {
                setHintCooldown((prev) => {
                    if (prev <= 100) {
                        clearInterval(interval);
                        return 0;
                    }
                    return prev - 100;
                });
            }, 100);
        }
        return () => clearInterval(interval);
    }, [hintCooldown])

    useEffect(() => {
        if (hintButtonRef.current) {
            const progress = (hintCooldown / 7000) * 100;
            hintButtonRef.current.style.setProperty('--cooldown-progress', `${progress}%`);
        }
    }, [hintCooldown]);

    const handleHint = () => {
        if (hintCooldown > 0) return;
        setHintCooldown(7000);

        let rowToHint = props.currentRow !== null 
            ? ctx.puzzle.find(item => item.id === props.currentRow)
            : ctx.puzzle.find(item => item.id === 1);

        if (!rowToHint) return;

        const currentInput = ctx.userInput[rowToHint.id];
        const correctWord = rowToHint.word;

        const isCorrectSoFar = currentInput.every((letter, index) => 
            letter === '' || letter === correctWord[index]
        );

        if (!isCorrectSoFar) {
            props.onHint({ type: 'incorrect', rowId: rowToHint.id });
            return;
        }

        const indexToFill = currentInput.findIndex(letter => letter === '');
        if (indexToFill === -1) return;

        props.onHint({
            type: 'fill',
            rowId: rowToHint.id,
            index: indexToFill,
            letter: correctWord[indexToFill]
        });
    };

    return (
        <button 
            onClick={handleHint}
            disabled={hintCooldown > 0}
            ref={hintButtonRef}
        >
            Hint
        </button>
    );
}

export { HintButton };