import React, { useContext, useEffect, useRef, useState } from 'react';

import { PuzzleContext } from '../context/PuzzleContext.js';
import { PiTimerBold, PiTimerFill } from "react-icons/pi";
import { fetchPuzzle, formatTime } from '../helpers/utils.js';
import { MiniGameGrid } from './MiniGameGrid.js';

function Puzzles(props) {
    const ctx = useContext(PuzzleContext).puzzleInfo;
    const [detailViewIndex, setDetailViewIndex] = useState(null);
    const [detailSummary, setDetailSummary] = useState(null);
    const puzzleRef = useRef(null);

    useEffect(() => {
        const puzzleContainer = puzzleRef.current.closest('.puzzle-container');
        if (puzzleContainer) {
            puzzleContainer.scrollIntoView({ 
                behavior: "smooth", 
                block: "center",
                duration: 600 
            });
        }
    }, [ctx.puzzleIndex]);

    const onClick = (i) => {        
        if (detailViewIndex === i) {
            setDetailViewIndex(null);
            setDetailSummary(null);
            return;
        }

        if (!ctx.userData[i].solved) {
            props.onNext([fetchPuzzle(i), i]);
            setDetailViewIndex(null);
            setDetailSummary(null);
        } else {
            setDetailViewIndex(i);
            setDetailSummary(fetchPuzzle(i, true));
        }
    }

    const puzzles = Array.from({ length: ctx.numPuzzles }, (_, i) => {
        var p = ctx.userData[i];

        return (
            <div className='puzzle-container' key={i}>
                <button
                    className={`puzzle ${p.solved ? 'solved' : ''} ${ctx.puzzleIndex === i ? 'active' : ''}`} 
                    disabled={ctx.puzzleIndex === i}
                    ref={ctx.puzzleIndex === i ? puzzleRef : null}
                    onClick={() => onClick(i)}
                >
                    <span>Climbcross #{p.id}</span>
                    <span>
                        {!p.solved ? <PiTimerBold /> : <PiTimerFill />}
                        <span>{!p.solved ? "----" : formatTime(p.time)}</span>
                    </span>
                </button>
                <div className={i === detailViewIndex ? 'active' : ''}>
                    {i === detailViewIndex && (
                        <MiniGameGrid puzzle={detailSummary}/>
                    )}
                </div>
            </div>
        )
    });

    return (
        <div className="puzzles">
            {puzzles}
        </div>
    )
}

export { Puzzles }