import React, { useState, useContext, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import { Row } from './Row.js'
import { Timer } from './Timer.js';
import { ModalContext } from '../context/ModalContext.js';
import { WinModal } from './WinModal.js';
import { HintButton } from './HintButton.js';
import { PuzzleContext } from '../context/PuzzleContext.js';
import { fetchNextPuzzle } from '../helpers/utils.js';
import { Puzzles } from "./Puzzles";

function Crossclimb() {
    const ctx = useContext(PuzzleContext).puzzleInfo;
    const setCtx = useContext(PuzzleContext).setPuzzleInfo;
    const { setModal } = useContext(ModalContext);
    const [currentRow, setCurrentRow] = useState(null);
    const [middleSolved, setMiddleSolved] = useState(false);
    const [solved, setSolved] = useState(false);
    // const [swap, setSwap] = useState(false);
    const [unlocked, setUnlocked] = useState(false);
    const inputRefs = useRef(ctx.puzzle.map(() => Array(4).fill().map(() => React.createRef())));
    const [incorrectRow, setIncorrectRow] = useState(null);
    
    const getCurrentClue = () => {
        if (currentRow === null) return '';
        const item = ctx.puzzle.find(item => item.id === currentRow);
        return item ? `${item.clue}` : '';
    };

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const newItems = Array.from(ctx.puzzle);
        const [draggedItem] = newItems.splice(result.source.index + 1, 1);
        newItems.splice(result.destination.index + 1, 0, draggedItem);
        setCtx(prevCtx => {
            return { ...prevCtx, puzzle: newItems };
        });
        checkSolution(newItems, ctx.userInput);
    }

    const handleInputChange = (id, index, value) => {
        const newUserInput = {
            ...ctx.userInput,
            [id]: ctx.userInput[id].map((letter, i) => i === index ? value.toUpperCase() : letter)
        };
        setCtx(prevCtx => {
            return { ...prevCtx, userInput: newUserInput };
        });
        checkSolution(ctx.puzzle, newUserInput);
    };

    const handleFocusChange = (direction, currentId) => {
        if (solved) return;

        const currentRowIndex = ctx.puzzle.findIndex(item => item.id === currentId);
        let nextRowIndex;

        if (direction === 'up') {
            nextRowIndex = currentRowIndex > 0 ? currentRowIndex - 1 : ctx.puzzle.length - 1;
        } else {
            nextRowIndex = currentRowIndex < ctx.puzzle.length - 1 ? currentRowIndex + 1 : 0;
        }

        const firstEmptyInput = (rowIndex) => {
            const rowId = ctx.puzzle[rowIndex].id;
            const firstEmptyIndex = ctx.userInput[rowId].findIndex(input => input === '');
            return firstEmptyIndex === -1 ? 0 : firstEmptyIndex;
        };

        if (unlocked) {
            if (currentRowIndex === 0) {
                inputRefs.current[6][firstEmptyInput(6)].current.focus();
            } else {
                inputRefs.current[0][firstEmptyInput(0)].current.focus();
            }
        } else {
            if (!middleSolved && nextRowIndex !== 0 && nextRowIndex !== 6) {
                inputRefs.current[nextRowIndex][firstEmptyInput(nextRowIndex)].current.focus();
            }
        }
    };

    const handleHint = (hintAction) => {
        if (hintAction.type === 'incorrect') {
            setIncorrectRow(hintAction.rowId);
            setTimeout(() => setIncorrectRow(null), 3000);
            return;
        } else if (hintAction.type === 'fill') {
            const newUserInput = {
                ...ctx.userInput,
                [hintAction.rowId]: ctx.userInput[hintAction.rowId].map((letter, index) => 
                    index === hintAction.index ? hintAction.letter : letter
                )
            };
            setCtx(prevCtx => {
                return { ...prevCtx, userInput: newUserInput };
            });
            checkSolution(ctx.puzzle, newUserInput);

            const nextEmptyIndex = newUserInput[hintAction.rowId].findIndex((letter, index) => 
                index > hintAction.index && letter === ''
            );
            if (nextEmptyIndex !== -1) {
                const rowIndex = ctx.puzzle.findIndex(item => item.id === hintAction.rowId);
                inputRefs.current[rowIndex][nextEmptyIndex].current.focus();
            }
        }
    };

    const checkSolution = (currItems, currUserInputs) => {
        if (!middleSolved) {
            const middleItems = currItems.slice(1, -1);
            const isCorrect = middleItems.every(item => item.word === currUserInputs[item.id].join(''));
            
            if (isCorrect) {
                const isOrdered = middleItems.every((item, index, array) => 
                    index === 0 || 
                    (array[0].id < array[array.length - 1].id 
                        ? item.id > array[index - 1].id  // Increasing order
                        : item.id < array[index - 1].id) // Decreasing order
                );

                if (isOrdered) {
                    setMiddleSolved(true);
                    setCtx(prevCtx => {
                        return { ...prevCtx, swapped: (middleItems[0].id > middleItems[middleItems.length - 1].id) };
                    });
                    setTimeout(() => {
                        setUnlocked(true); 
                        setTimeout(() => {
                            inputRefs.current[0][0].current.focus();
                        }, 500);
                    }, 500);
                } else {
                    setMiddleSolved(false);
                }
            }
        } else {
            if (currItems[0].word === currUserInputs[0].join('') && currItems[6].word === currUserInputs[6].join('')) {
                setSolved(true);
                setCtx(prevCtx => {
                    var newUserData = [...ctx.userData];
                    newUserData[ctx.puzzleIndex] = {
                        id: ctx.puzzleIndex + 1,
                        solved: true,
                        time: ctx.time
                    }
                    return {...prevCtx, userData: newUserData};
                });
                setModal(<WinModal onNext={onNext}/>);
            }
        }
    };

    const onNext = (p) => {
        setCurrentRow(null);
        setMiddleSolved(false);
        setSolved(false);
        setUnlocked(false);
        setIncorrectRow(null);
        setCtx(prevCtx => {
            var newPuzzle = p ? p : fetchNextPuzzle(ctx.puzzleIndex, ctx.userData);
            var startingUserInput = prevCtx.puzzle.reduce((acc, item) => ({
                ...acc,
                [item.id]: ['', '', '', '']
            }), {})
            return { 
                ...prevCtx, 
                puzzle: newPuzzle[0], 
                puzzleIndex: newPuzzle[1],
                userInput: startingUserInput, 
                time: 0,
                swapped: false 
            };
        });
        setModal(false);
    }

    return (
        <div className="game-container">
            <Puzzles onNext={onNext}/>
            <div className='options'>
                <Timer isSolved={solved}/>
                <HintButton 
                    onHint={handleHint}
                    currentRow={currentRow}
                />
            </div>
            <div className='game'>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <span className='line' />
                    <span className='line' />
                    <Row
                        end 
                        immutable={!unlocked} 
                        id={ctx.swapped ? 6 : 0} 
                        setCurrentRow={setCurrentRow}
                        handleInputChange={handleInputChange} 
                        middleSolved={middleSolved} 
                        solved={solved}
                        onFocusChange={handleFocusChange}
                        inputRefs={inputRefs.current[ctx.swapped ? 6 : 0]}
                        isIncorrect={incorrectRow === (ctx.swapped ? 6 : 0)}
                    />
                    <Droppable droppableId='rows'>
                        {(provided) => (
                            <ul className='inner-rows' ref={provided.innerRef} {...provided.droppableProps}>
                                {ctx.puzzle.slice(1, -1).map((e, i) => (
                                    <Draggable key={e.id} draggableId={e.id.toString()} index={i}>
                                        {(provided, snapshot) => {
                                            var t = provided.draggableProps.style.transform
                                            // console.log(t)
                                            if (t) {
                                                // let y = parseInt(t.split(',')[1].slice(0, -3));
                                                // let ny = Math.min(Math.max(y, -(i*60)), 240-(i*60));
                                                // console.log(provided.draggableProps)
                                                // provided.draggableProps.style.transform = `translate(0px,${y}px)`;
                                            }

                                            return (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <Row 
                                                        id={e.id} 
                                                        setCurrentRow={setCurrentRow}
                                                        handleInputChange={handleInputChange} 
                                                        provided={provided} 
                                                        snapshot={snapshot} 
                                                        middleSolved={middleSolved}
                                                        solved={solved}
                                                        onFocusChange={handleFocusChange}
                                                        inputRefs={inputRefs.current[i+1]}
                                                        isIncorrect={incorrectRow === e.id}
                                                    />
                                                </li>
                                            )
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                    <Row 
                        end 
                        immutable={!unlocked} 
                        id={ctx.swapped ? 0 : 6} 
                        setCurrentRow={setCurrentRow}
                        handleInputChange={handleInputChange} 
                        middleSolved={middleSolved}
                        solved={solved}
                        onFocusChange={handleFocusChange}
                        inputRefs={inputRefs.current[ctx.swapped ? 0 : 6]}
                        isIncorrect={incorrectRow === (ctx.swapped ? 0 : 6)}
                    />
                </DragDropContext>
            </div>
            <div className='clue'>{getCurrentClue()}</div>
        </div>
    )
}

export { Crossclimb }