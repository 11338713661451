import React, { useContext, useEffect, memo } from 'react';

import { PiTimerBold, PiTimerFill } from "react-icons/pi";
import { ModalContext } from '../context/ModalContext';
import { PuzzleContext } from '../context/PuzzleContext';
import { formatTime } from '../helpers/utils';

const Timer = memo(function Timer(props) {
    const ctx = useContext(PuzzleContext).puzzleInfo;
    const setCtx = useContext(PuzzleContext).setPuzzleInfo;
    const { isModalActive } = useContext(ModalContext)

    useEffect(() => {
        let interval = null;
        if (!props.isSolved && !isModalActive) {
            interval = setInterval(() => {
                setCtx(prevCtx => {
                    return { ...prevCtx, time: (prevCtx.time + 1) };
                });
            }, 1000);
        } else if (props.isSolved && ctx.time !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [props, isModalActive, ctx.time, setCtx]);

    return (
        <div className="timer">
            {props.isSolved ? <PiTimerFill/> : <PiTimerBold/>}
            {formatTime(ctx.time)}
        </div>
    );
});

export { Timer }