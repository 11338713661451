import React, { createContext, useState } from 'react';
import { fetchPuzzle, getNumPuzzles, LOCAL_STORAGE_KEY } from '../helpers/utils';

const numPuzzles = getNumPuzzles();

var startingUserData = Array.from({ length: numPuzzles }, (_, i) => ({
    id: i + 1,
    solved: false,
    time: 0
}));

const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
if (storedData) {
    if (JSON.parse(storedData).length === startingUserData.length) {
        startingUserData = JSON.parse(storedData);
    } else {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(startingUserData));
    }
} else {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(startingUserData));
}

const firstUnsolvedPuzzle = () => {
    for (let i = 0; i < numPuzzles; i++) {
        if (!startingUserData[i].solved) {
            return i;
        }
    }
    return 0;
};

var puzzleIndex = firstUnsolvedPuzzle();
var startingPuzzle = fetchPuzzle(puzzleIndex);

var startingUserInput = startingPuzzle.reduce((acc, item) => ({
    ...acc,
    [item.id]: ['', '', '', '']
}), {})

var startingPuzzleInfo = {
    numPuzzles: numPuzzles,
    puzzle: startingPuzzle,
    puzzleIndex: puzzleIndex,
    userInput: startingUserInput,
    time: 0,
    swapped: false,
    userData: startingUserData
};

const PuzzleContext = createContext();
const PuzzleProvider = props => {
    const [puzzleInfo, setPuzzleInfo] = useState(startingPuzzleInfo);
    const value = { puzzleInfo, setPuzzleInfo };
    
    return (
        <PuzzleContext.Provider value={value}>
            {props.children}
        </PuzzleContext.Provider>
    );
};

export { PuzzleContext, PuzzleProvider };
