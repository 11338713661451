import './App.css';
import { Site } from './components/Site';
import { ModalProvider } from './context/ModalContext';
import { PuzzleProvider } from './context/PuzzleContext';

function App() {
  return (
    <div className="App">
      <PuzzleProvider>
        <ModalProvider>
          <Site />
        </ModalProvider>
      </PuzzleProvider>
    </div>
  );
}

export default App;
