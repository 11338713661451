import React, { useState, useEffect, useContext } from "react";

import { ModalContext } from "../context/ModalContext";
import { PiEqualsBold } from "react-icons/pi";

function InstructionsModal() {
    const { setModal } = useContext(ModalContext);
    const [boldState, setBoldState] = useState(0);
    const [focusIndex, setFocusIndex] = useState(0);
    const [textRevealState, setTextRevealState] = useState(1);

    useEffect(() => {
        const timer = setInterval(() => {
            setBoldState((bs) => (bs + 1) % 2);
        }, 1200);

        const secondTimer = setInterval(() => {
            setFocusIndex((fi) => (fi + 1) % 5);
            setTextRevealState((trs) => (trs + 1) % 5);
        }, 600);


        return () => {clearInterval(timer); clearInterval(secondTimer);};
    }, []);

    const boldValues = boldState === 0 ? [2, 2, undefined] : [undefined, 0, 0];
    const revealedText = ["S", "A", "V", "E"].slice(0, textRevealState).concat(Array(4 - textRevealState).fill(""));

    return (
        <div className="instructions modal">
            <h3>Instructions</h3>
            <div id="instructions">
                <div>
                    <div className="instruction">
                        <span>1. Guess the answer to each clue.</span>
                        <div className="demo">
                            <span className='line' />
                            <span className='line' />
                            <DemoRow focus text={["P", "A", "", ""]} bold={2}/>
                        </div>
                        <div className='clue'>Walk back and forth nervously</div>
                    </div>
                    <div className="instruction">
                        <span>2. Reorder the rows to form a word ladder, where each word differs by one letter</span>
                        <div className="demo">
                            <span className='line' />
                            <span className='line' />
                            <DemoRow text={["P", "A", "V", "E"]} bold={boldValues[0]}/>
                            <DemoRow dragged focus text={["P", "A", "C", "E"]} bold={boldValues[1]}/>
                            <DemoRow text={["F", "A", "C", "E"]} bold={boldValues[2]}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="instruction">
                        <span>3. Finish the top and bottom rows after they are unlocked to complete the puzzle</span>
                        <div className="demo">
                            <span className='line' />
                            <span className='line' />
                            <DemoRow end text={revealedText} bold={focusIndex}/>
                            <DemoRow disabled text={["P", "A", "V", "E"]}/>
                            <DemoRow disabled text={["P", "A", "C", "E"]}/>
                            <DemoRow disabled text={["F", "A", "C", "E"]}/>
                            <DemoRow end text={["", "", "", ""]}/>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={() => setModal(false)}>Let's get started!</button>
        </div>
    )
}

function DemoRow(props) {
    return (
        <div className={`row-container ${props.end ? 'immutable' : ''}`}>
            <span className="handle">
                <span className={`handle-icon ${props.disabled ? '' : 'active'}`}><PiEqualsBold/></span>
            </span>
            <div className={`word ${props.focus ? 'focus' : ''} ${props.dragged ? 'dragging' : ''}`}>
                <input type="text" value={props.text[0]} disabled className={`${props.bold === 0 ? 'bold' : ''} ${props.disabled ? 'disabled' : ''}`}/>
                <input type="text" value={props.text[1]} disabled className={`${props.bold === 1 ? 'bold' : ''} ${props.disabled ? 'disabled' : ''}`}/>
                <input type="text" value={props.text[2]} disabled className={`${props.bold === 2 ? 'bold' : ''} ${props.disabled ? 'disabled' : ''}`}/>
                <input type="text" value={props.text[3]} disabled className={`${props.bold === 3 ? 'bold' : ''} ${props.disabled ? 'disabled' : ''}`}/>
            </div>
            <span className="handle">
                <span className={`handle-icon ${props.disabled ? '' : 'active'}`}><PiEqualsBold/></span>
            </span>
        </div>
    )
}

export { InstructionsModal }